import { 
    getBasketFromCookie,
    basketChangeFetch, 
    addToBasket, 
    removeFromBasket, 
    getCookieIndividual,
    userLoggedIn,
    getCookiesBasket,
} from './cookies.js'
import { 
    addCard, 
    sendArea, 
    calculateTotalPriceLocal
} from './pages/basket/add-cards.js';
import { 
    findParentWithClass,
    debounce,
} from './utils.js';
import { sendFormWithToken } from './validation.js';
import { changeBubbleValue } from './wishlist.js';

export function basket() {

    const basketClear = document.querySelectorAll('.basket_clear');
    const basketFull = document.querySelector('.basket_full');

    const deleteAllBtn = document.querySelector('.del_sel_products');

    const basketList = document.getElementById('basket__list');
    

    const addBtns = document.querySelectorAll('.add_to_basket');
    const changeBtns = document.querySelectorAll('.change_counter_value');

    const addBtnsTurnkey = document.querySelectorAll('.add_to_basket_turkney-card');
    const changeBtnsTurnkey = document.querySelectorAll('.change_counter_value_turnkey');


    if(addBtnsTurnkey.length > 0) {
        const basket = getBasketFromCookie()
        addBtnsTurnkey.forEach((btn) => {
            const turkneyCard = findParentWithClass(btn, 'turkney-card');
            const turkneyCardLarge = document.querySelectorAll('.turkney-card_large');
            if(!userLoggedIn()) {
                const productCardsSmall = turkneyCard.querySelectorAll('.product-card_size_small');
                const productCardsMicro = turkneyCard.querySelectorAll('.product-card_size_micro');
                let flag = true
                let productsId = []
                basket.forEach((prdct) => {
                    productsId.push(prdct['product']);
                });
                if(productCardsSmall.length > 0) {
                    productCardsSmall.forEach((card) => {
                        const cardId = card.getAttribute('data-id');
                        if(!productsId.includes(Number(cardId))) {
                            flag = false;
                        }
                    })
                }
                if(turkneyCardLarge.length > 0) {
                    productCardsMicro.forEach((card) => {
                        const cardId = card.getAttribute('data-id');
                        if(!productsId.includes(Number(cardId))) {
                            flag = false;
                        }
                    })
                }
                if(flag) btn.classList.add('all-in-basket')
            }
            btn.addEventListener('click',() => {
                const productCardsSmall = turkneyCard.querySelectorAll('.product-card_size_small');
                const productCardsMicro = turkneyCard.querySelectorAll('.product-card_size_micro');
                const input = btn.parentElement.querySelector('input');
                input.value = 1;
                btn.classList.add('in-basket');
                if(userLoggedIn()) {
                    if(productCardsSmall.length > 0) {
                        fetchToBasket(productCardsSmall, 'add/');
                    }
                    if(turkneyCardLarge.length > 0) {
                        fetchToBasket(productCardsMicro, 'add/');
                    }
                } else {
                    if(productCardsSmall.length > 0) {
                        addToBasketTurnkey(productCardsSmall, input.value, 'plus'); 
                    }
                    if(turkneyCardLarge.length > 0) {
                        addToBasketTurnkey(productCardsMicro, input.value, 'plus');    
                    }

                    changeBubbleValue('basket')
                }
            });
        });
    };

    if(changeBtnsTurnkey.length > 0) {
        changeBtnsTurnkey.forEach((btn) => {
            btn.addEventListener('click',() => {
                const turkneyCard = findParentWithClass(btn, 'turkney-card');
                const turkneyCardLarge = document.querySelectorAll('.turkney-card_large');
                const productCardsSmall = turkneyCard.querySelectorAll('.product-card_size_small');
                const productCardsMicro = turkneyCard.querySelectorAll('.product-card_size_micro');
                const input = btn.parentElement.querySelector('input');
                const addToBasketBtn = btn.parentElement.parentElement.querySelector('.add_to_basket_turkney-card')
                let inputVal = parseInt(input.value);
                if(btn.classList.contains('minus')) {
                    if(inputVal > 1) {
                        inputVal -= 1;
                        if(userLoggedIn()) {
                            if(productCardsSmall.length > 0) {
                                fetchToBasket(productCardsSmall, 'decrement/');
                            }
                            if(turkneyCardLarge.length > 0) {
                                fetchToBasket(productCardsMicro, 'decrement/');
                            }
                        } else {
                            if(productCardsSmall.length > 0) {
                                addToBasketTurnkey(productCardsSmall, inputVal);
                            }
                            if(turkneyCardLarge.length > 0) {
                                addToBasketTurnkey(productCardsMicro, inputVal);
                            }
                        }
                    } else {
                        addToBasketBtn.classList.remove('in-basket');
                        if(userLoggedIn()) {
                            if(productCardsSmall.length > 0) {
                                fetchToBasket(productCardsSmall, 'remove/');
                            }
                            if(turkneyCardLarge.length > 0) {
                                fetchToBasket(productCardsMicro, 'remove/');
                            }
                        } else {
                            if(productCardsSmall.length > 0) {
                                removeFromBasketTurnkey(productCardsSmall)
                            }
                            if(turkneyCardLarge.length > 0) {
                                removeFromBasketTurnkey(productCardsMicro);
                            }
                        }
                    }
                } else if(btn.classList.contains('plus')) {
                    inputVal += 1;
                    if(userLoggedIn()) {
                        if(productCardsSmall.length > 0) {
                            fetchToBasket(productCardsSmall, 'add/');                         
                        }
                        if(turkneyCardLarge.length > 0) {
                            fetchToBasket(productCardsMicro, 'add/'); 
                        }
                    } else {
                        if(productCardsSmall.length > 0) {
                            addToBasketTurnkey(productCardsSmall, inputVal, 'plus'); 
                        }
                        if(turkneyCardLarge.length > 0) {
                            addToBasketTurnkey(productCardsMicro, inputVal, 'plus');
                        }
                    }
                }
                input.value = inputVal >= 0 ? inputVal : 0;

                changeBubbleValue('basket');
            })
        })
    
    }

    if(addBtns.length > 0) {
        addBtns.forEach((el) => {
            const input = Array.from(el.parentNode.querySelector('.button_counter_basic').children).find(el => el.localName == 'input');
            input.value = input.value.trim();
            el.addEventListener('click',() => {
                const parent = el.parentNode;
                const id = el.querySelector('input').value;
                const parsedId = parseInt(id);
                const input = Array.from(parent.querySelector('.button_counter_basic').children).find(el => el.localName == 'input')
                input.value = 1;
                el.classList.add('in-basket');
                if(userLoggedIn()) {
                    let producObj = {};
                    producObj['product'] = parsedId;
                    producObj['quantity'] = input.value;
                    basketChangeFetch(producObj, 'add/', userLoggedIn())
                    .then(res=>{
                        changeBasketBubble(res);
                    })
                } else {
                    removeFromBasket(Number(id));
                    addToBasket(Number(id), 1);


                    changeBubbleValue('basket');
                }
            });
            input.addEventListener('input',()=>{
                input.value = input.value.replace(/\D/g, '');
                debouncedChangeWithValue(el);
            });
        });
    }

    if(changeBtns.length > 0) {
        changeBtns.forEach((btn) => {
            const findInput = Array.from(btn.parentNode.children).find(el => el.localName == 'input');
            const findAddBtn = Array.from(btn.parentNode.parentNode.children).find(el => el.classList.contains('add_to_basket'));
            const findId = Array.from(findAddBtn.children).find(el => el.localName == 'input').value;
            if(userLoggedIn()) {
    
            } else {
                Array.from(getCookieIndividual())
                .forEach((el) => {
                    if(el.product == findId) {
                        findAddBtn.classList.add('in-basket');
                        findInput.value = el.quantity;
                    }
                })
            }
            btn.addEventListener('click',() => {
                let inputVal = parseInt(findInput.value);
                if (btn.classList.contains('plus')) {
                    inputVal += 1;
                    if (userLoggedIn()) {
                        const parsedId = parseInt(findId);
                        let producObj = {};
                        if(parsedId) {
                            producObj['product'] = parsedId;
                        }
                        if(window.location.pathname.includes('/basket/')) {
                            basketChangeFetch(producObj, 'add/', userLoggedIn())
                            .then(res=> {
                                changeCurValue(res);
                            })
                        } else {
                            basketChangeFetch(producObj, 'add/', userLoggedIn())
                            .then(res=>{
                                changeBasketBubble(res);
                            })
                        }
                    }
                }
                else if (btn.classList.contains('minus')) {
                    inputVal -= 1;
                    if(userLoggedIn()) {
                        if (inputVal <= -1) {
                            const parsedId = parseInt(findId);
                            let producObj = {};
                            if(parsedId) {
                                producObj['product'] = parsedId;
                            }
                            if(window.location.pathname.includes('/basket/')) {
                                basketChangeFetch(producObj, 'remove/', userLoggedIn())
                                .then(res=> {
                                    changeCurValue(res);
                                })
                            } else {
                                basketChangeFetch(producObj, 'remove/', userLoggedIn())
                                .then(res=>{
                                    changeBasketBubble(res);
                                })
                            }
                        } else {
                            const parsedId = parseInt(findId);
                            let producObj = {};
                            if(parsedId) {
                                producObj['product'] = parsedId;
                            }
                            if(window.location.pathname.includes('/basket/')) {
                                basketChangeFetch(producObj, 'decrement/', userLoggedIn())
                                .then(res=> {
                                    changeCurValue(res);
                                })
                            } else {
                                basketChangeFetch(producObj, 'decrement/', userLoggedIn())
                                .then(res=>{
                                    changeBasketBubble(res);
                                })
                            }
                        }
                    }
                }
                if (!userLoggedIn()) {
                    removeFromBasket(Number(findId));
                }
                findInput.value = inputVal >= 0 ? inputVal : 0;
                if (inputVal <= 0) {
                    findAddBtn.classList.remove('in-basket');
                } else {
                    if (!userLoggedIn()) {
                        addToBasket(Number(findId), Number(findInput.value));   
                    }
                }

                changeBubbleValue('basket');
            });
        });
    }
    
    if(window.location.pathname.includes('/basket/')) {

        if(userLoggedIn()) {
            allCheckboxesState(document);
            const cards = document.querySelectorAll('.product-card_vertical');
            cards.forEach((productCard) => {
                const price = productCard.querySelector('.product-card_vertical__cost-block__cost');

                
                const counterBtn = productCard.querySelector('.button_counter_basic');
                const changeBtnsCards = productCard.querySelectorAll('.change_counter_value');
                const deleteBtnCard = productCard.querySelectorAll('.delete_this_product');
                const inputVal = counterBtn ? counterBtn.querySelector('input').value : 0;
                let newVal = parseInt(inputVal);

                const productId = productCard.querySelector('.product__id').value;

                changeBtnsCards.forEach((el) => {
                    el.addEventListener('click', () => {
                        const producObj = {};
                        producObj.product = productId;
                        if (el.classList.contains('plus')) {
                            newVal += 1;
                        } else if (el.classList.contains('minus')) {
                            if(!newVal <= 0) {
                                newVal -= 1;
                            }
                        }
                    })
                });

                let productToDel = {};
                productToDel['product'] = productId
                deleteBtnCard.forEach((btn) => {
                    btn.addEventListener('click',() => {
                        basketChangeFetch(productToDel, 'remove/', userLoggedIn());
                        window.location.reload();
                    })
                });

                 // Функционал для колеровки красок

                const productSlider = productCard.querySelector('.slider-local');
                const productSquare = productCard.querySelector('.square-local');
                const area = productCard.querySelector('.area__value');
                const palette = productCard.querySelector('.palette__id');

                $(function () {
                    $(productSlider).slider({
                        range: "min",
                        max: 999,
                        slide: function (event, ui) {
                            $(productSquare).val(ui.value);
                            debouncedCalculateTotalPrice(productSquare, palette.value, price);
                            sendArea(productCard, ui);
                        }
                    });

                    $(productSquare).val($(productSlider).slider("value"));

                    $(productSquare).on("input", function () {
                        let value = $(this).val();
                        $(productSlider).slider("value", value);

                        debouncedCalculateTotalPrice(productSquare, palette.value, price);
                        sendArea(productCard, ui);
                    });

                    setTimeout(() => {
                        if(productSquare) {
                            productSquare.value = area.value;
                            $(productSlider).slider("value", area.value);
                        }          
                    }, 150);
                });

            })
            const checkbox = document.querySelectorAll('.select_to_delete');
            deleteAllBtn.addEventListener('click', () => {
                checkbox.forEach((el) => {
                    const productId = el.value;
                    let productToDel = {};
                    productToDel['product'] = productId;
                    if (el.checked) {
                        basketChangeFetch(productToDel, 'remove/', userLoggedIn());
                        window.location.reload();
                    }
                })
            })
            let flag = false
            checkbox.forEach((el) => {
                el.addEventListener('click',() => {
                    flag = false
                    checkbox.forEach((evt) => {
                        if (evt.checked) {
                            flag = true
                        }
                    })
                    if(flag) {
                        moveToOrderBtn.classList.remove('button_accent_disabled')
                    } else {
                        moveToOrderBtn.classList.add('button_accent_disabled')
                    } 
                })
            })
            document.addEventListener('DOMContentLoaded',() => {
                setTimeout(() => {
                    if(deleteAllCheckbox) {
                        deleteAllCheckbox.click()
                    }
                }, 1000);
            })
        } else {
            if(getCookiesBasket().length > 3) {
                changeCurValue();
                if(basketFull) {
                    basketFull.classList.add('full');
                }
                
                if(basketList) {

                    let processedCount = 0;
                    const basketItems = Array.from(getBasketFromCookie());
                    const totalCount = basketItems.length;

                    if (totalCount === 0) {
                        callback();
                        return;
                    }
                    
                    basketItems
                    .forEach((el) => {
                        getProduct(el['product'])
                        .then (data => {
                            const {productCard, hr} = addCard(data, el);
                            basketList.appendChild(productCard)
                            basketList.appendChild(hr);

                            processedCount++;
                            if (processedCount === totalCount) {
                                if(deleteAllCheckbox) {
                                    allCheckboxesState(basketList);
                                    deleteAllCheckbox.click()
                                }
                            }
                        })
                    })
        
                    // Удаление множества товаров одновременно
        
        
                    deleteAllBtn.addEventListener('click', () => {
                        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
                        checkboxes.forEach((el) => {
                            if(el.classList.contains('select_to_delete')) {
                                const foundId = el.value;
                                if(el.checked) {
                                    removeFromBasket(Number(foundId));
                                    window.location.reload();
                                }
                            }
                        })
                    });
                    
                }
            } else {
                if(basketClear.length > 0) {
                    basketClear.forEach((el) => {
                        el.classList.add('clear')
                    });
                }
            }
        }
    }

    const addCustomColorBtn = document.getElementById('add-custom-color-btn');
    if(addCustomColorBtn) {
        const dialog = document.querySelector('.dialog-success');
        const valueMeters = document.querySelector('.value-meters');
        const squareInput = document.getElementById('square');
        
        addCustomColorBtn.addEventListener('click',() => {
            const palletBlock = findParentWithClass(addCustomColorBtn, 'painting-section__pallet-block');
            const radioInputs = document.querySelectorAll('[type="radio"]');
            const areaInput = document.getElementById('square');
            let data = {};
            // data['area'] = areaInput.value;
            data['area'] = 1;
            // valueMeters.innerText = squareInput.value;
            valueMeters.innerText = 1;
            radioInputs.forEach((input) => {
                if(input.checked) {
                    if(input.name == 'pallet') data['palette'] = input.value;
                    if(input.name == 'palletColor') data['color'] = input.value;
                    if(input.name == 'palletHue') data['shade'] = input.id.replace(/\D/g, "");
                }
            });
            if(userLoggedIn()) {
                sendFormWithToken(data, '/api/basket/add-custom-color/')
                    .then(data => {
                        dialog.classList.add('active');
                        addCustomColorBtn.classList.add('button_accent_disabled');
                        setTimeout(() => {
                            dialog.classList.remove('active');
                            addCustomColorBtn.classList.remove('button_accent_disabled');
                        }, 5000);
                    })
            } else {
                sendForm(data, '/api/basket/add-custom-color/')
                    .then(data=> {
                        addToBasket(data.product_id, 1);
                        dialog.classList.add('active');
                        addCustomColorBtn.classList.add('button_accent_disabled');
                        setTimeout(() => {
                            dialog.classList.remove('active');
                            addCustomColorBtn.classList.remove('button_accent_disabled');
                        }, 5000);
                    })
            }
        })
    }

    const basketValue = document.querySelector('.basket-value');
    if(basketValue) {
        if(userLoggedIn()) {
            if(basketValue.innerText.trim() !== '0') {
                basketValue.style.display = 'flex';
            };
        } else {
            if(getBasketFromCookie().length > 0) {
                let totalQuantity = '';
                getBasketFromCookie().forEach((el) => {
                    const quantity = Number(el['quantity']);
                    totalQuantity = Number(totalQuantity) + quantity;
                })
                basketValue.innerText = totalQuantity;
                basketValue.style.display = 'flex';
            }
        }
    }
}

const debouncedCalculateTotalPrice = debounce(calculateTotalPriceLocal, 750)


const deleteAllCheckbox = document.getElementById('checkboxAll');
const moveToOrderBtn = document.getElementById('move-to-order');

export function allCheckboxesState(field)  {
    if (deleteAllCheckbox) {
        const checkboxes = field.querySelectorAll('.select_to_delete');
        deleteAllCheckbox.addEventListener('click',() => {
            const newStateChecked = deleteAllCheckbox.checked;
            if(newStateChecked) {
                checkboxes.forEach((el) => {
                    if(el.classList.contains('select_to_delete')) {
                        moveToOrderBtn.classList.remove('button_accent_disabled')
                        el.checked = true;
                    }
                })
            } else {
                checkboxes.forEach((el) => {
                    if(el.classList.contains('select_to_delete')) {
                        moveToOrderBtn.classList.add('button_accent_disabled')
                        el.checked = false;
                    }
                })
            }
        });
        checkboxes.forEach((el) => {
            el.addEventListener('click',() => {
                deleteAllCheckbox.checked = false;
                getAllStates()
            })
        })
        function getAllStates() {
            let lengths = 0;
            let flag = false;
            checkboxes.forEach((el) => {
                if(el.checked) {
                    lengths += 1;
                    flag = true;
                } else {
                    lengths -= 1;
                }
            });
            flag ? moveToOrderBtn.classList.remove('button_accent_disabled') : moveToOrderBtn.classList.add('button_accent_disabled');
            if (checkboxes.length == lengths) deleteAllCheckbox.checked = true
        }
    }
}

// Изменение значений total полей в корзине незарегистрированного пользователя
const fullPrice = document.getElementById('full_price');
const fullBasicPrice = document.getElementById('full_basic_price');
const fullDiscount = document.getElementById('full_discount');
const curQuantity = document.getElementById('current_quantity');
const fullWeight = document.getElementById('current_weight');


export async function changeCurValue(res) {
    if(userLoggedIn()) {
        if(res && res.status) {

            const bubbleValue = document.querySelector('.basket-value');
            bubbleValue.innerText = res.basket_info.total_quantity;

            if(fullPrice.innerText == 'Цена по запросу') return;

            const basketInfo = res.basket_info;
            const totalWeight = basketInfo.total_weight;
            const totalDiscount = basketInfo.total_discount;
            const totalPrice = basketInfo.total_price;
            const totalBasicPrice = basketInfo.total_price_without_discount;
            const totalQuantity = basketInfo.total_quantity;

            fullPrice.innerText = totalPrice + '₽';
            fullBasicPrice.innerText = totalBasicPrice + '₽';
            fullDiscount.innerText = totalDiscount;
            curQuantity.innerText = totalQuantity;
            fullWeight.innerText = totalWeight;
        } else {
            return;
        }
    } else {
        const productsCookie = await getBasketFromCookie();
        if(productsCookie.ok) return productsCookie;

        const products = {};
        products.products = productsCookie;

        sendForm(products, '/api/basket/get-basket-info/')
        .then(response=>{
            if(response.status) {

                const bubbleValue = document.querySelector('.basket-value');
                bubbleValue.innerText = response.basket_info.total_quantity;
                
                if(fullPrice && fullPrice.innerText == 'Цена по запросу') return;

                const basketInfo = response.basket_info;
                const totalWeight = basketInfo.total_weight;
                const totalDiscount = basketInfo.total_discount;
                const totalPrice = basketInfo.total_price;
                const totalBasicPrice = basketInfo.total_price_without_discount;
                const totalQuantity = basketInfo.total_quantity;

                if(fullPrice) {
                    fullPrice.innerText = totalPrice + '₽';
                    fullBasicPrice.innerText = totalBasicPrice + '₽';
                    fullDiscount.innerText = totalDiscount;
                    curQuantity.innerText = totalQuantity;
                    fullWeight.innerText = totalWeight;
                }
            } else {
                return;
            }
        })
    }
}

// Общие технические функции

export function concatenateDigits(str) {
    if(str) {
        const cleanedString = String(str).replace(/[^0-9.]/g, '');
        if (cleanedString) {
            return cleanedString;
        }
    } else {
        return '';
    }
}

async function getProduct(curId) {
    const response = await fetch('/api/products/' + curId, {
        method: "GET",
    });
    if(response.ok) return response.json();
    else throw new Error(response.statusText);
};

function removeFromBasketTurnkey(productCards) {
    let productCardsId = [];
    productCards.forEach((card) => {
        productCardsId.push(card.getAttribute('data-id'));
    });
    productCardsId.forEach((id) => {
        removeFromBasket(id);
    });
};

function addToBasketTurnkey(productCards, quantity) {
    let productCardsId = [];
    productCards.forEach((card) => {
        productCardsId.push(card.getAttribute('data-id'));
    });
    productCardsId.forEach((id) => {
        if(id) {
            removeFromBasket(parseInt(id));
            addToBasket(parseInt(id), parseInt(quantity));
        }
    });
};

function changeBasketBubble(res) {
    const bubbleValue = document.querySelector('.basket-value');
    const basketInfo = res.basket_info;
    const totalQuantity = basketInfo.total_quantity;

    bubbleValue.style.display = 'none'

    if(totalQuantity !== 0) bubbleValue.style.display = 'flex';

    bubbleValue.innerText = totalQuantity;
}


function fetchToBasket(productCards, url) {
    productCards.forEach((card) => {
        let producObj = {};
        const productCardId = card.getAttribute('data-id');
        if(productCardId) {
            producObj['product'] = parseInt(productCardId);
            basketChangeFetch(producObj, url, userLoggedIn())
            .then(res=>{
                changeBasketBubble(res);
            })
        }
    });
}


async function sendForm(data, url) {
    const csrfToken = $('[name=csrfmiddlewaretoken]').val();
    const response = await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': "application/json",
            'X-CSRFToken': csrfToken,
        },
        body: JSON.stringify(data),
    });
    if(response.ok) return response.json();
    else throw new Error(response.statusText);
};

export function changeBasketWithValue(addBtn) {
    const id = addBtn.querySelector('input').value;
    const input = Array.from(addBtn.parentNode.querySelector('.button_counter_basic').children).find(addBtn => addBtn.localName == 'input');
    const parsedId = parseInt(id);
    if(userLoggedIn()) {
        let producObj = {};
        producObj['product'] = parsedId;
        let productWithQuan = {};
        productWithQuan['product'] = parsedId;
        productWithQuan['quantity'] = input.value;
        basketChangeFetch(producObj, 'remove/', userLoggedIn())
        .then(res=>{
            changeBasketBubble(res);
        })
        setTimeout(() => {
            if(productWithQuan.quantity !== '') {
                basketChangeFetch(productWithQuan, 'add/', userLoggedIn())
                .then(res=>{
                    changeBasketBubble(res);
                    if(fullPrice) {
                        changeCurValue(res);
                    }
                })
            } else {
                addBtn.classList.remove('in-basket');
            }
        }, 100);
    } else {
        removeFromBasket(id);
        if(!!parseInt(input.value)) {
            addToBasket(id, parseInt(input.value));
        } else {
            addBtn.classList.remove('in-basket');
        }

        if(fullPrice) {
            changeCurValue()
        }

        changeBubbleValue('basket');
    }
}
const debouncedChangeWithValue = debounce(changeBasketWithValue, 500);